<template>
  <div>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="form">
        <div class="row">
          <div class="col-12 pt-5">
            <h6>
              <v-icon class="pb-1" v-text="'mdi-numeric-1-box-outline'" />
              {{ $t("department.information_department") }}
            </h6>
          </div>
          <div class="col-md-12">
            <validation-provider
              v-slot="{ errors }"
              name="name"
              rules="required|max:200"
            >
              <v-text-field
                v-model="model.name"
                oninput="this.value = this.value.toUpperCase()"
                :counter="100"
                :error-messages="errors"
                :label="$t('label.name')"
                required
              />
            </validation-provider>
          </div>
          <div class="col-md-12">
            <validation-provider
              v-slot="{ errors }"
              name="description"
              rules="required|max:255|regex:^[0-9A-ZÁÉÍÑÓÚÜ]+(?:[\s][0-9A-ZÁÉÍÑÓÚÜ]+)*$"
            >
              <v-textarea
                outlined
                v-model="model.description"
                :counter="200"
                oninput="this.value = this.value.toUpperCase()"
                rows="3"
                :error-messages="errors"
                :label="$t('label.description')"
                required
              />
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button
              v-if="model.idDepartment != ''"
              class="btn btn-outline-success"
              type="submit"
              :disabled="invalid || sending"
            >
              <i class="feather icon-save mr-1"></i> {{ $t("global.edit_deparment") }}
            </button>
            <button
              v-else
              class="btn btn-outline-success"
              type="submit"
              :disabled="invalid || sending"
            >
              <i class="feather icon-save mr-1"></i> {{ $t("global.save_deparment") }}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { UtilFront } from "@/core";
import { EmployeeRequest } from "@/core/request";

import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  data() {
    return {
      model: {
        idDepartment: "",
        name: "",
        description: "",
      },
      sending: false,
    };
  },
  methods: {
    form() {
      this.model.idDepartment != ""
        ? this.updateDeparmement()
        : this.addDepartment();
    },
    updateDeparmement() {
      if (!this.sending) {
        this.sending = true;
        this.loading = UtilFront.getLoadding();
        let infoUser = UtilFront.getDataUser();

        EmployeeRequest.updateDeparmement({
          idDepartment: this.model.idDepartment,
          name: this.model.name.trim(),
          description: this.model.description.trim(),
          idUser: UtilFront.getDataUser().idUser,
          userBranchOfficeDTO: {
            idUser: infoUser.idUser,
            idBranchOffice: 1,
          },
        })
          .then((response) => {
            let { success, message } = response.data.data;
            if (success) {
              this.$emit("alert", "success", "Update_Success_0001");
              this.$emit("changeAction", 1);
            } else {
              this.$emit("alert", "error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$emit("alert", "internal");
          })
          .finally(() => {
            this.sending = false;
            this.loading.hide();
          });
      }
    },
    addDepartment() {
      if (!this.sending) {
        this.sending = true;
        this.loading = UtilFront.getLoadding();
        let infoUser = UtilFront.getDataUser();

        //console.log(infoUser);

        EmployeeRequest.addDepartment({
          name: this.model.name.trim(),
          description: this.model.description.trim(),
          idUser: UtilFront.getDataUser().idUser,
          userBranchOfficeDTO: {
            idUser: infoUser.idUser,
            idBranchOffice: 1,
          },
        })
          .then((response) => {
            let { success, message } = response.data.data;
            if (success) {
              this.$emit("alert", "success", "Add_Success_0001");
              this.$emit("changeAction", 1);
            } else {
              this.$emit("alert", "error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$emit("alert", "internal");
          })
          .finally(() => {
            this.loading.hide();
            this.sending = false;
          });
      }
    },
    cancel() {
      this.model.name = this.model.description = this.model.idDepartment = "";
      this.$refs.observer.reset();
    },
    reloadComponentUpdate(item) {
      this.model = item;
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
