<template>
  <div id="containerbar">
    <div class="rightbar">
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("department.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("department.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a @click="changeAction(1)">{{
                    $t("department.configuration")
                  }}</a>
                </li>
                <li class="breadcrumb-item">{{ $t(title) }}</li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="withPermissionName(permissions.CAT_DEP_ADD_01) && showAction(1)"
              @click="changeAction(2)"
            >
              <i class="ti-plus mr-2"></i> {{ $t("global.add_department") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title">
                      <i class="fa fa-building-o mr-2" aria-hidden="true"></i>
                      {{ $t(title) }}
                    </h5>
                  </div>
                </div>
              </div>
              <EmployeeTableComponent
                v-on:excelTable="excelTable"
                :isUpdate="withPermissionName(permissions.CAT_DEP_EDIT_02)"
                v-if="withPermissionName(permissions.DEPARTMENTS)"
                v-show="showAction(1)"
                :dataList="departments"
                :headers="deparmentHeaders"
                @updateItem="edit"
              />
              <div class="card-body" v-show="showAction(2) || showAction(3)">
                <AddDeparmentComponent
                  @alert="alert"
                  ref="formAddDeparment"
                  @changeAction="changeAction"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { UtilFront, PermissionConstants } from "@/core";
import { EmployeeRequest, ExcelRequest } from "@/core/request";
import EmployeeTableComponent from "@/components/employee/EmployeeTableComponent";
import AddDeparmentComponent from "@/components/employee/AddDeparmentComponent";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import { DEPARTMENT } from "@/core/DataTableHeadersLocale";

export default {
  data() {
    return {
      /* Table */
      deparmentHeaders: DEPARTMENT(),
      action: 1,
      departments: [],
      /* Permisos */
      permissions: PermissionConstants,
    };
  },
  computed: {
    title() {
      return this.action == 1
        ? "department.department"
        : this.action == 2
        ? "department.registration_department"
        : "department.edit_deparment";
    },
  },
  methods: {
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    edit(item) {
      this.changeAction(3);
      this.$refs.formAddDeparment.reloadComponentUpdate(item);
    },
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      this.action = action;
      this.action == 1
        ? this.listAll()
        : this.action == 2
        ? this.reload()
        : null;
    },
    listAll() {
      this.loading = UtilFront.getLoadding();
      EmployeeRequest.listAllDepartment({
        idUser: UtilFront.getDataUser().idUser,
      })
        .then((response) => {
          let { success, data, message } = response.data.data;
          success ? (this.departments = data) : this.alert("error", message);
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    reload() {
      this.$refs.formAddDeparment.cancel();
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportDepartments({
        idUser: UtilFront.getDataUser().idUser,
      });
    },
  },
  created() {
    this.listAll();
  },
  watch: {
    "$i18n.locale": function() {
      this.deparmentHeaders = DEPARTMENT();
    },
  },
  components: {
    EmployeeTableComponent,
    AddDeparmentComponent,
    AlertMessageComponent,
  },
};
</script>
